import React from 'react';
import { SvgIcon } from '@mui/material';

export const IconClose = (rest) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...rest}>
      <path
        opacity="0.12"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#818C99"
      />
      <path
        d="M27.894 12.106C28.4798 12.6918 28.4798 13.6415 27.894 14.2273L22.1216 20L27.894 25.7727C28.4309 26.3097 28.4757 27.1525 28.0282 27.7404L27.894 27.894C27.3082 28.4798 26.3584 28.4798 25.7727 27.894L20 22.1217L14.2273 27.894C13.6415 28.4798 12.6918 28.4798 12.106 27.894C11.5202 27.3082 11.5202 26.3585 12.106 25.7727L17.8783 20L12.106 14.2273C11.569 13.6903 11.5242 12.8475 11.9717 12.2596L12.106 12.106C12.6918 11.5202 13.6415 11.5202 14.2273 12.106L20 17.8783L25.7727 12.106C26.3584 11.5202 27.3082 11.5202 27.894 12.106Z"
        fill="#818C99"
      />
    </SvgIcon>
  );
};
