import {
  alpha,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { SubscriptionToCaptcha } from './components/SubscriptionToCaptcha';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FileInput } from './components/FileInput';
import { IconClose } from 'icons/Close';
import { checkEmail, checkPersonName, checkPhone, checkTelegram } from 'utils/check';
import { FieldSelect } from './components/FieldSelect';
import { theme } from 'theme';

const getFormFields = (internshipsList) => {
  return [
    {
      Component: TextField,
      type: "text",
      label: "ФИО *",
      placeholder: "ФИО",
      name: "PersonName",
      attrs: {
        inputProps: {
          maxLength: 255,
          required: true,
        }
      },
      validate: (data) => {
        if (!data.PersonName) {
          return "Необходимо указать ФИО";
        }
        if (!checkPersonName(data.PersonName)) {
          return "Введите корректное ФИО";
        }

        return;
      },
    },
    {
      Component: TextField,
      type: "text",
      label: "Телефон *",
      placeholder: "+7 (999) 999-99-99",
      name: "Phone",
      validate: (data) => {
        if (!data.Phone) {
          return "Необходимо указать номер телефона";
        }
        if (!checkPhone(data.Phone)) {
          return "Введите корректный номер телефона";
        }
        return;
      },
      attrs: {
        inputProps: {
          maxLength: 255,
          required: true,
        }
      }
    },
    {
      Component: TextField,
      type: "text",
      label: "Telegram",
      name: "Telegram",
      attrs: {
        inputProps: {
          maxLength: 1024,
        }
      },
      validate: (data) => {

        if (data.Telegram && !checkTelegram(data.Telegram)) {
          return "Введите корректный Телеграм";
        }

        return;
      }

    },
    {
      Component: TextField,
      type: "text",
      label: "Почта *",
      name: "Email",
      attrs: {
        inputProps: {
          maxLength: 255,
          required: true,
        }
      },
      validate: (data) => {
        if (!data.Email) {
          return "Необходимо указать почту";
        }
        if (!checkEmail(data.Email)) {
          return "Введите корректную почту";
        }

        return;
      }
    },
    {
      Component: FieldSelect,
      label: "Направление *",
      name: "InternshipCode",
      placeholder: "Выберите направление",
      attrs: {
        selectList: internshipsList,
        required: true
      },
      validate: (data) => {
        if (!data.InternshipCode) {
          return "Необходимо указать направление";
        }

        return;
      }
    },
    {
      Component: TextField,
      type: "text",
      label: "Ссылка на резюме",
      name: "ResumeLink",
      attrs: {
        inputProps: {
          maxLength: 1024,
        }
      },
      validate: (data) => {
        if (!data.ResumeLink && !data.File) {
          return "Необходимо загрузить файл или указать ссылку на резюме";
        }
        return;
      },
    },
    {
      Component: TextField,
      type: "text",
      label: "Откуда ты узнал про стажировку?",
      name: "HowFindAbout",
      attrs: {
        inputProps: {
          maxLength: 1500,
        }
      },
    },
    {
      Component: FileInput,
      type: "file",
      name: "File",
      validate: (data) => {
        if (!data.ResumeLink && !data.File) {
          return "Необходимо загрузить файл или указать ссылку на резюме";
        }

        return;
      }
    }
  ];
}

const ERROR_MESSAGES = {
  400: "Выбран не корректный тип файла, прикрепите резюме в формате doc, docx, rtf или pdf",
  403: "Необходимо пройти капчу для безопасной отправки данных",
  422: "Необходимо указать все обязательные параметры",
  502: "Технические проблемы на сервере. Зайдите немного позже, мы работаем над их решением.",
};

export const DialogStatement = ({ open, onClose , internshipsList}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (internshipsList.length === 0) {
    return null;
  }
  const formFields = getFormFields(internshipsList);

  const handleFieldChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: ""
    }));

    if (["ResumeLink", "File"].includes(name)) {
      setFormErrors((prev) => ({
        ...prev,
        ResumeLink: "",
        File: ""
      }));
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const errors = formFields.reduce((acc, cur) => {
      if (cur.validate) {
        return ({
          ...acc,
          [cur.name]: cur.validate(formValues),
        });
      }

      return acc;
    }, {});

    if (Object.values(errors).filter(Boolean).length > 0) {
      setFormErrors(errors);
    } else {
      if (captchaStatus !== "success" || !captchaToken) {
        errors.captcha = "Подтвердите, что вы не робот";
        setFormErrors(errors);
      } else if (!formValues.agreement) {
        errors.agreement = "Ошибка";
        setFormErrors(errors);
      } else {
        setIsFetching(true);
        const body = {
          ...formValues,
          capcha: captchaToken,
        };
        try {
          const response = await fetch(
            `/api/v1/internship/request`,
            {
              method: "POST",
              body: Object.keys(body).reduce((acc, cur) => {
                acc.append(cur, body[cur]);
                return acc;
              }, new FormData()),
            }
          );
          if (!response.ok) {
            const error =  new Error(`${response.status} ${response.statusText}`);
            error.code = response.status
            throw error
          }
          setFormValues({});
          setIsSuccessful(true);
        } catch (error) {
          console.error(error);
          const errorMessage = ERROR_MESSAGES[error.code] || error.message;
          enqueueSnackbar(errorMessage, {variant: "error"});
        } finally {
          setIsFetching(false);
        }
      }
    }
  };
  return (
    <Dialog
      open={open}
      sx={{
        "& form": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 20,
          pt: 10,
          pb: 20,
          maxWidth: 708,
          height: "auto",
          width: "100%",
          borderRadius: 5,
          [theme.breakpoints.down("md")]: {
            p: 6,
          }
        }
      }}
      PaperProps={{
        component: "form",
        onSubmit: handleFormSubmit,
        noValidate: true,
      }}
    >
      <DialogTitle
        sx={{
          p: 0,
          mb:isSuccessful? 8 : 10,
          fontSize: 40,
          fontFamily: "Manrope",
          fontWeight: 500,
          textTransform: "none",
          textAlign: "center",
          [theme.breakpoints.down("md")]: {
            fontSize: isSuccessful? 40 :24,
            lineHeight: isSuccessful? "44px" : "28px",
            mb: 4,
          }
        }}
      >
        { isSuccessful? "Отклик отправлен" : "Составление заявки" }
      </DialogTitle>
      { !isSuccessful && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            p: 0,
            color: "#818C99",
            backgroundColor: alpha("#818C99", 0.12),
            borderRadius: 12.5,

          }}
        >
          <IconClose
            sx={{
              width: 40,
              height: 40,
              [theme.breakpoints.down("md")]: {
                width: 24,
                height: 24,
              }
            }}
          />
        </IconButton>
      )}
      <DialogContent
        sx={{
          py: 2,
          px: 0,
          overflow:"visible",
          width: "100%"
        }}
      >
        {isSuccessful?
          (
            <>
              <Typography
                variant="body1"
                sx={{
                  mb: 6,
                  p: 0,
                  maxWidth: 508,
                  fontFamily:"Manrope",
                  fontWeight: 500,
                  textAlign: "center",
                  [theme.breakpoints.down("md")]: {
                    maxWidth:"none",
                    width: "100%",
                  }
                }}
              >
                Мы&nbsp;получили вашу заявку, внимательно её&nbsp;рассмотрим и&nbsp;ответим.<br/>
                Тестовое задание по ссылке: <Link href="https://t.me/pgstart_bot">https://t.me/pgstart_bot</Link>
              </Typography>
            </>
          ):(
            <>
              <Stack
                spacing={isTabletScreen? 4 : 6}
                direction="column"
                sx={{
                  mb: 6,
                  [theme.breakpoints.down("md")]: {
                    mb: 4,
                  }
                }}
              >
                {formFields.map(({ Component, attrs, label, name, placeholder }) => {
                  return (
                    <Component
                      {...attrs}
                      key={name}
                      name={name}
                      placeholder={placeholder}
                      value={formValues[name] || ""}
                      error={Boolean(formErrors[name])}
                      helperText={formErrors[name]}
                      label={label}
                      onChange={(event) => {
                        handleFieldChange(name, event.target.value);
                      }}
                    />
                  );
                })}
              </Stack>

              <SubscriptionToCaptcha
                error={formErrors.captcha}
                onStatusChange={(status) => {
                  setFormErrors({});
                  setCaptchaStatus(status);
                }}
                onTokenChange={(token) => setCaptchaToken(token)}
              />

              <Box
                sx={{
                  ml: "-9px",
                  mt: 12,
                  [theme.breakpoints.down("md")]: {
                    mt: 6,
                  }
                }}
              >
                <Stack sx={{ mb: 6 }} direction="row" alignItems="center">
                  <Checkbox
                    value="agreement"
                    name="agreement"
                    checked={formValues.agreement || false}
                    sx={{
                      color: formErrors.agreement ? "error.main" : "common.black"
                    }}
                    onChange={(event) => {
                      handleFieldChange("agreement", event.target.checked);
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: 1.4,
                      ml: 1,
                      color: formErrors.agreement ? "error.main" : ""
                    }}
                  >
                    Нажимая кнопку «Отправить отклик», я даю согласие на обработку предоставленных мною
                    персональных данных на условиях настоящего{" "}
                    <Box
                      component="a"
                      href="/files/Согласие_на_обработку_персональных_данных.pdf"
                      target="_blank"
                      sx={{
                        color: "inherit",
                        textDecorationLine: "underline"
                      }}
                    >
                      согласия на обработку персональных данных.
                    </Box>
                  </Typography>
                </Stack>
              </Box>
            </>
          )}
      </DialogContent>
      <DialogActions
        sx={{
          p: 0,
          width: "100%"
        }}
      >
        {!isSuccessful && (
          <Button
            variant="contained"
            type="submit"
            sx={{
              fontSize: 18,
              width: "100%"
            }}
            disabled={isFetching}
          >
            {isFetching
              ? <CircularProgress />
              : "Отправить заявку"}
          </Button>
        )}
        {isSuccessful && (
          <Button
            variant="contained"
            sx={{
              fontSize: 18,
              width: "100%"
            }}
            aria-label="close"
            onClick={() => {
              onClose();
              setIsSuccessful(false);
            }}
          >
            Понятно
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
