import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { basicColors } from 'theme/basic-colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { theme } from 'theme/index';
export const QandASection = () => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      component="section"
      sx={{
        mb: isMiddleScreen ? 9 : 26.5
      }}
    >
      <Stack
        direction="column"
        sx={{
          maxWidth: "1068px",
          margin: "0 auto",
          px: isMiddleScreen ? 6 : 3.5
        }}
      >
        <Typography variant="h2" sx={{ mb: 10 }}>
          Ответы на вопросы
        </Typography>
        <Box id="accordionQA">
          {questionsAndAnswers.map((item, index) => (
            <Accordion
              sx={{
                mb: 5,
                px: 6,
                py: 2,
                backgroundColor: basicColors.secondary.main,
                borderRadius: "32px !important",
                boxShadow: "none",
                "&::before": {
                  content: "none"
                }
              }}
              key={index}
            >
              <AccordionSummary
                id={`panel${index}-content`}
                aria-controls={`panel${index}-content`}
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  "& Mui-expanded ": {
                    minHeight: "20px"
                  },

                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    m: 0,
                    maxHeight: "23px"
                  }
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 500, m: 0 }}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

const questionsAndAnswers = [
  {
    question: "Какая зарплата?",
    answer: "От 60 000 до 100 000 рублей в месяц при полном рабочем дне"
  },
  {
    question: "Можно ли совмещать стажировку с учебой?",
    answer:
      "Да, если ты можешь уделять стажировке не меньше 20 часов в неделю (в рабочее время), а через год выйти на полную ставку"
  },
  {
    question: "Что писать в заявке?",
    answer:
      "После получения твоей заявки, у нас должно быть достаточно информации для принятия решения о приглашении тебя на следующий этап. Рекомендуем оставлять актуальные контакты и прикрепить резюме. Если у тебя нет опыта коммерческой разработки, то добавь в резюме информацию об учебных проектах, участии в олимпиадах, пройденных курсах и пр."
  },
  {
    question: "Как пройти отбор?",
    answer: (
      <>
        Смотри раздел <a href="#progress">“Как проходит отбор”</a>
      </>
    )
  },
  {
    question: "Что будет, если мне не понравятся задачи по выбранному направлению?",
    answer: "Если есть открытые стажерские позиции в других командах, вы сможете пройти отбор туда"
  }
];
