import { basicColors } from "../basic-colors.js";

export const MuiInputBase = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: basicColors.black,
      fontFamily: "Manrope",
      borderColor: basicColors.black,

      "& .MuiOutlinedInput-notchedOutline": {
        border: "thin solid",
        borderColor: basicColors.secondary.dark,
        borderRadius: 12
      },
    }),
    input: ({ theme }) => ({
      "&:-webkit-autofill": {
        WebkitBoxShadow: "none !important",
        WebkitTextFillColor: "inherit !important",
      },
    })
  }
};
