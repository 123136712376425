import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Stack, Typography, useMediaQuery } from '@mui/material';
import { basicColors } from 'theme/basic-colors';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import { theme } from 'theme';

export const DirectionsSection = ({ data, onClick }) => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleChipClick = (internshipCode) => {
    setSelectedCategory(internshipCode);
  };
  useEffect(() => {
    if (data && data.length > 0 && !selectedCategory) {
      setSelectedCategory(data[0]?.internshipCode);
    }
  }, [data, selectedCategory]);

  if (data.length === 0) {
    return null;
  }

  return (
    <Box
      component="section"
      sx={{
        mb: isMiddleScreen ? 15 : 22.5
      }}
    >
      <Stack
        direction="column"
        sx={{
          maxWidth: "1068px",
          margin: "0 auto",
          px: isMiddleScreen ? 6 : 3.5
        }}
      >
        <Box
          sx={{
            marginBottom: 12
          }}
        >
          <Typography
            variant="h2"
            sx={{
              width: "50%"
            }}
          >
            Направления стажировки
          </Typography>
        </Box>
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{
            mb: 12,
            [theme.breakpoints.down("md")]:{
              display: "block",
            }
          }}
        >
          {data.map(({ internshipCode, internshipName }) => (
            <Box
              key={internshipCode}
              sx={{
                p: 1.5
              }}
            >
              <Chip
                key={internshipCode}
                label={internshipName}
                variant={selectedCategory === internshipCode ? "contained" : "outlined"}
                onClick={() => handleChipClick(internshipCode)}
              />
            </Box>
          ))}
        </Stack>
        <Box
          sx={{
            mb: isMiddleScreen ? 8 : 12
          }}
        >
          {data.map(({
            introduction,
            requirements,
            responsibilities,
            internshipCode,
          }) => (
            <Box
              key={internshipCode}
              sx={{
                display: selectedCategory === internshipCode ? "block" : "none"
              }}
            >
              <Box
                sx={{
                  mb: isMiddleScreen ? 8 : 12
                }}
                dangerouslySetInnerHTML={{ __html: introduction }}
              />
              <Stack
                direction={isMiddleScreen ? "column" : "row"}
                sx={{
                  width: "100%"
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    px: isMiddleScreen ? 0 : 8,
                    py: isMiddleScreen ? 6 : 8
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: isMiddleScreen ? 700 : 400,
                      height: isMiddleScreen ? "auto" : "6.625rem",
                      mb: isMiddleScreen ? 5 : 0
                    }}
                  >
                    Что нужно, чтобы попасть на&nbsp;стажировку?
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "2px",
                      backgroundColor: basicColors.primary.main,
                      borderRadius: 1.5,
                      mb: isMiddleScreen ? 5 : 8
                    }}
                  />
                  <Box
                    sx={{
                      lineHeight: "1.5rem"
                    }}
                    dangerouslySetInnerHTML={{ __html: requirements }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    px: isMiddleScreen ? 0 : 8,
                    py: isMiddleScreen ? 6 : 8
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: isMiddleScreen ? 700 : 400,
                      height: isMiddleScreen ? "auto" : "6.625rem",
                      mb: isMiddleScreen ? 5 : 0
                    }}
                  >
                    Чему вы научитесь?
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "2px",
                      backgroundColor: basicColors.primary.main,
                      borderRadius: 1.5,
                      mb: isMiddleScreen ? 5 : 8
                    }}
                  />
                  <Box
                    sx={{
                      lineHeight: "1.5rem"
                    }}
                    dangerouslySetInnerHTML={{ __html: responsibilities }}
                  />
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
        <Button
          variant="contained"
          endIcon={<ArrowRightIcon />}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 8
          }}
          onClick={onClick}
        >
          Отправить заявку
        </Button>
      </Stack>
    </Box>
  );
};


