import { Box, Stack, Typography } from '@mui/material';
import { theme } from 'theme';
import { basicColors } from 'theme/basic-colors';

const lineStyles = {
  content: '""',
  position: "absolute",
  left: 0,
  right: 0,
  height: 6,
  borderRadius: 12,
  background: `
    linear-gradient(
      to right,
      ${basicColors.primary.main} 15%,
      ${basicColors.purple},
      ${basicColors.pink},
      ${basicColors.purple})
  `,
};

const stages = [
  {
    id: "1",
    color: basicColors.primary.main,
    src: "/img/progres-1.png",
    srcSet: "/img/progres-1-2x.png 2x, /img/progres-1-3x.png 3x",
    text: "Заполни заявку",
    alt: "Заполни заявку"
  },
  {
    id: "2",
    color: basicColors.purple,
    src: "/img/progres-2.png",
    srcSet: "/img/progres-2-2x.png 2x, /img/progres-2-3x.png 3x",
    text: "Получи тестовое</br>задание",
    alt: "Получи тестовое задание"
  },
  {
    id: "3",
    color: basicColors.pink,
    src: "/img/progres-3.png",
    srcSet: "/img/progres-3-2x.png 2x, /img/progres-3-3x.png 3x",
    text: "Пройди интервью</br>с рекрутером</br>и командой",
    alt: "Пройди интервью с рекрутером и командой"
  },
  {
    id: "4",
    src: "/img/progres-4.png",
    color: basicColors.purple,
    srcSet: "/img/progres-4-2x.png 2x, /img/progres-4-3x.png 3x",
    text: "Получи оффер</br>на стажировку",
    alt: "Получи оффер на стажировку"
  },
];

export const ProgressSection = () => {
  return (
    <Box
      component="section"
      id="progress"
      sx={{
        maxWidth: 1068,
        mx: "auto",
        mb: 22.5,
        px: 3.5,
        scrollMarginTop: 100,
        [theme.breakpoints.down("sm")]:{
          mb: 15,
          px:6,
        }
      }}
    >
      <Typography
        variant="h2"
        sx={{
          mb: 22
        }}
      >
        Как проходит отбор
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          position: "relative",
        }}
      >
        {stages.map(({
          id,
          src,
          srcSet,
          text,
          color,
          alt
        }, index) => (
          <Stack
            key={id}
            direction="row"
            sx={{
              width: "25%",
              [theme.breakpoints.down("sm")]: {
                width: "50%",
              },
              "&:first-of-type": {
                [theme.breakpoints.down("sm")]: {
                  pb: 5,
                },
                "&::before": {
                  ...lineStyles,
                  top: 103,
                  [theme.breakpoints.down("sm")]: {
                    top: 90,
                  },
                },
              },
              "&:nth-of-type(2)": {
                justifyContent: "center",
                [theme.breakpoints.down("sm")]: {
                  justifyContent: "flex-end",
                  pb: 5,
                },
              },
              "&:nth-of-type(3)": {
                justifyContent: "center",
                [theme.breakpoints.down("sm")]: {
                  justifyContent: "flex-start",
                },
                "&::before": {
                  [theme.breakpoints.down("sm")]: {
                    ...lineStyles,
                    top: 335,
                  },
                },
              },
              "&:nth-of-type(4)": {
                justifyContent: "flex-end",
              },
            }}
          >
            <Box>
              <Box
                sx={{
                  color,
                  fontSize: 60,
                  mb: 15,
                  [theme.breakpoints.up("md")]: {
                    fontSize: 80,
                  }
                }}
              >
                {id}
              </Box>
              <Box />
              <Box
                sx={{
                  mb: 5,
                }}
              >
                <Box
                  component="img"
                  src={src}
                  srcSet={srcSet}
                  alt={alt}
                />
              </Box>
              <Box
                sx={{
                  fontSize: "1rem",
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </Box>
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
