import { useEffect, useMemo, useState } from 'react';
import { Header } from './components/Header/Header';
import { HeroSection } from './components/HeroSection/HeroSection';
import { DirectionsSection } from './components/DirectionsSection/DirectionsSection';
import { ConditionsSection } from './components/ConditionsSection/ConditionsSection';
import { ProgressSection } from './components/ProgressSection/ProgressSection';
import { NewsSection } from './components/newsSection/NewsSection';
import { QandASection } from './components/QandASection/QandASection';
import { Footer } from './components/Footer/Footer';
import { DialogStatement } from './components/DialogStatement/DialogStatement';
import { CircularProgress, Stack } from '@mui/material';
export const MainPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [list, setList]= useState([]);
  const [loading, setLoading] = useState(true);
  const admission = useMemo(() => Boolean(list.length), [list.length]);
  const filteredList = useMemo(() => (list.map(({ internshipName, internshipCode }) => ({ internshipName, internshipCode }))), [list]);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickCloseDialog = () => {
    setOpenDialog(false);
  };
  const loadList = async () => {
    try {
      setLoading(true)
      const response = await fetch("/api/v1/internship/list", { method: "POST" });
      if (response.ok) {
        const data = await response.json();
        setList(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    loadList();
  }, []);

  if (loading) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width:"100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <>
      <Header
        onClick={handleClickOpenDialog}
        showBtn={admission}
      />
      <main>
        <HeroSection
          onClick={handleClickOpenDialog}
          admission={admission}
        />
        {
          admission && <DirectionsSection
          data={list}
          onClick={handleClickOpenDialog}
        />
        }
        <ConditionsSection />
        <ProgressSection />
        <NewsSection />
        <QandASection />
        <DialogStatement
          open={openDialog}
          onClose={handleClickCloseDialog}
          internshipsList={filteredList}
        />
      </main>
      <Footer
        onClick={handleClickOpenDialog}
        showBtn={admission}
      />
    </>
  );
};
