import React from 'react';
export const ArrowRightIcon = () => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="29" height="27" rx="13.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7556 14.6307C23.0815 14.2717 23.0815 13.7283 22.7556 13.3693L19.508 9.79031C19.3455 9.61129 19.1193 9.5 18.8702 9.5C18.6212 9.5 18.3949 9.61129 18.2325 9.79031C18.0631 9.97696 17.9881 10.2275 17.9881 10.4211C17.9881 10.6146 18.0631 10.8651 18.2325 11.0518L20.072 13.0789L6.88208 13.0789C6.63834 13.0789 6.40416 13.1721 6.2348 13.3587C6.06944 13.541 6 13.7738 6 14C6 14.2262 6.06944 14.459 6.2348 14.6413C6.40416 14.8279 6.63834 14.9211 6.88208 14.9211L20.072 14.9211L18.2325 16.9482C17.9067 17.3072 17.9067 17.8507 18.2325 18.2097C18.3949 18.3887 18.6212 18.5 18.8702 18.5C19.1193 18.5 19.3455 18.3887 19.508 18.2097L22.7556 14.6307Z"
        fill="#7B7B7C"
      />
    </svg>
  );
};
