import React, { useState } from 'react';
import { Box, Typography, Stack, CircularProgress, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';
import { IconPlus } from 'icons/Plus';
import { formatBytes } from 'utils/formatBytes';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const MAX_FILE_SIZE = 15 * 1024 * 1024;

export const FileInput = ({ value, error, helperText, onChange }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    try {
      if (!file) {
        throw new Error('Добавьте файл');
      }

      if (file.size > MAX_FILE_SIZE) {
        throw new Error(`Файл должен весить меньше ${formatBytes(MAX_FILE_SIZE)}`);
      }
      onChange({ target: { value: file } });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.toString(), { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          p: 4,
          border: 'thin dashed',
          borderRadius: 3,
          borderColor: error ? 'error.main' : 'primary.main',
          color: error ? 'error.main' : 'common.black'
        }}
      >
        {isFetching ? (
          <CircularProgress color="inherit" sx={{ m: 'auto' }} />
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            component="label"
            sx={{
              cursor: 'pointer',
              wordBreak: 'break-word'
            }}
          >
            {value ? (
              value.name
            ) : (
              <>
                <IconPlus
                  sx={{
                    mr: 3,
                    color: error ? 'error.main' : 'primary.main',
                  }}
                />
                Загрузить файл резюме до {formatBytes(MAX_FILE_SIZE)}
              </>
            )}
            <VisuallyHiddenInput
              type="file"
              accept=".pdf,.rtf,.doc,.docx"
              onChange={handleFileChange}
            />
          </Stack>
        )}
        {value && (
          <IconButton
            size="small"
            sx={{ color: 'primary.main' }}
            onClick={() => {
              onChange({ target: { value: null } });
            }}
          >
            <ClearIcon />
          </IconButton>
        )}
      </Stack>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '0.75rem',
          lineHeight: 1.66,
          mt: 1,
          mx: 4,
          color: 'error.main'
        }}
      >
        {helperText}
      </Typography>
    </Box>
  );
};
