import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Link,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import { basicColors } from 'theme/basic-colors';
import { theme } from 'theme';

export const NewsSection = () => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadList = async () => {
    try {
      setLoading(true)
      const response = await fetch("/api/v1/news/list");
      if (response.ok) {
        const data = await response.json();
        if (data) {
          setList(data);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    loadList();
  }, []);

  if (loading) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          width:"100%",
        }}
      >
        <CircularProgress />
      </Stack>
    )
  }
  if (!list || list.length === 0) {
    return null;
  }
  return (
    <Box
      component="section"
      sx={{
        mb: isMiddleScreen ? 15 : 22.5
      }}
    >
      <Stack
        direction="column"
        sx={{
          maxWidth: "1068px",
          margin: "0 auto",
          px: isMiddleScreen ? 6 : 3.5
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mb: 10
          }}
        >
          <Typography
            variant="h2"
          >
            Новости
          </Typography>
          <Button
            component={Link}
            href="https://postgrespro.ru/tag/PGStart"
            target="_blank"
            rel="noreferrer"
            variant="contained"
            endIcon={<ArrowRightIcon />}
            sx={{
              display: isMiddleScreen ? "none" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: 245,
              height: "59px",
              color: basicColors.secondary.dark,
              textDecoration: "none",
              boxShadow: "none",
              backgroundColor: basicColors.secondary.main,
              "&:hover": {
                color: basicColors.secondary.dark,
                backgroundColor: basicColors.secondary.main
              },
              "&:active": {
                backgroundColor: basicColors.secondary.main
              }
            }}
          >
              <Typography
                variant="body1"
              >
                Все новости
              </Typography>
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          sx={{
            width: "100%"
          }}
        >
          {list.map(({id, text, image, link }) => {
            return (
              <Card

                key={id}
                sx={{
                  maxWidth: 325,
                  borderRadius: 8,
                  overflow: "hidden",
                  m: 2.5,
                  [theme.breakpoints.down("md")]: {
                    width: "100%"
                  },
                }}
              >
                <Link
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CardMedia
                    component="img"
                    image={`${image}.650x400.jpg`}
                    alt={text}
                    sx={{
                      objectFit: "fill",
                      width: "100%",
                    }}
                  />
                </Link>
              </Card>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};
