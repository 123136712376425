export const MuiButton = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.5,
      borderRadius: 40,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary?.contrastText,
      textTransform: 'none',
      padding: '16px'
    }),
    outlined: ({ theme }) => ({
      border: '2px solid',
      borderColor: theme.palette.primary.main,

      '&:hover': {
        border: '2px solid',
        borderColor: theme.palette.primary.main
      },

      '&:focus': {
        borderColor: theme.palette.primary.dark
      },

      '&.Mui-disabled': {
        borderColor: theme.palette.primary.light,
        color: theme.palette.common.white
      }
    }),
    contained: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      '&.Secondary': {
        backgroundColor: theme.palette.common.black
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },

      '&:active': {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark
      }
    })
  }
};
