export const basicColors = {
  white: '#FFFFFF',
  black: '#000000',
  primary: {
    light: '#65CCFF',
    main: '#1F5CE1',
    dark: '#1A8AC1'
  },
  secondary: {
    main: '#EBEBEB',
    lightDark: '#8E8E8E',
    dark: '#7B7B7C'
  },
  purple: '#6C58C2',
  pink: '#E5226C'
};
