import React, { useEffect, useState } from 'react';
import { Box, Button, Link, Stack, useMediaQuery } from '@mui/material';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import { Logo } from 'components/Logo';
import { theme } from 'theme';
import { basicColors } from 'theme/basic-colors';

export const Header = ({ onClick, showBtn }) => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const imgBlock = document.getElementById('img-block');
      if (imgBlock) {
        const { top } = imgBlock.getBoundingClientRect();
        setScrolled(top < 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      component="header"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
        backgroundColor: basicColors.white,
        boxShadow: scrolled ? ' 0px 4px 32px 0px rgba(0, 0, 0, 0.16)' : 'none',
        transition: 'box-shadow 0.3s'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        maxWidth="1068px"
        sx={{
          minHeight: isMiddleScreen ? '80px' : '90px',
          padding: isMiddleScreen ? 6 : 3.5
        }}
        margin="0 auto"
      >
        <Link href="https://postgrespro.ru/">
          <Logo />
        </Link>
        <Button
          variant="contained"
          sx={{
            display: scrolled && !isMiddleScreen && showBtn ? 'flex' : 'none'
          }}
          endIcon={<ArrowRightIcon />}
          onClick={onClick}
        >
          Отправить заявку
        </Button>
      </Stack>
    </Box>
  );
};
