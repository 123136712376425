import {MuiButton} from "./MuiButton";
import {MuiFormLabel} from "./MuiFormLabel";
import {MuiInputBase} from "./MuiInputBase";
import {MuiIconButton} from "./MuiIconButton";
import {MuiChip} from "./MuiChip";
import {MuiMenuItem} from "./MuiMenuItem"



export const components = {
  MuiButton,
  MuiFormLabel,
  MuiInputBase,
  MuiIconButton,
  MuiChip,
  MuiMenuItem
};
