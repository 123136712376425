import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "theme";
import {MainPage} from "pages/MainPage";
import { styled } from "@material-ui/core";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";


const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    color: theme.palette.common.black,
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  }
}));

function App() {
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }

  return (
    <SnackbarProvider
    Components={{
      success: StyledMaterialDesignContent
    }}>
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <MainPage />
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
