import { Box, Button, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { basicColors } from 'theme/basic-colors';
import { Logo } from 'components/Logo';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import React from 'react';
import { theme } from 'theme';
import { getCurrentYear } from 'utils/getCurrentYear';

export const Footer = ({ onClick, showBtn }) => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      component="header"
      sx={{
        backgroundColor: basicColors.black,
        py: isMiddleScreen ? 10 : 15
      }}
    >
      <Stack
        direction="column"
        sx={{
          maxWidth: "1068px",
          margin: "0 auto",
          px: isMiddleScreen ? 6 : 3.5
        }}
      >
        <Stack
          direction={isMiddleScreen ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isMiddleScreen ? "flex-start" : "center"}
          sx={{ mb: 20 }}
        >
          <Link
            href="https://postgrespro.ru/"
            sx={{
              mb: isMiddleScreen ? 5 : 0
            }}
          >
            <Logo color={basicColors.secondary.lightDark} />
          </Link>
          {showBtn && (
            <Button
              variant="contained"
              endIcon={<ArrowRightIcon />}
              sx={{
                width: isMiddleScreen ? "100%" : "auto",
                display: "flex",
                justifyContent: "space-between"
              }}
              onClick={onClick}
            >
              Отправить заявку
            </Button>
          )}
        </Stack>
        <Stack
          direction={isMiddleScreen ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isMiddleScreen ? "flex-start" : "center"}
          sx={{ mb: 20 }}
        >
          <Typography
            variant="h3"
            color={basicColors.secondary.lightDark}
            sx={{
              mb: isMiddleScreen ? 5 : 0
            }}
          >
            Остались вопросы?
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Link
              href="https://habr.com/ru/company/postgrespro/profile/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "35px",
                height: "35px",
                mr: 4,
                color: basicColors.white,
                backgroundColor: "#5E5E5E",
                borderRadius: "50%",
                textDecoration: "none"
              }}
            >
              H
            </Link>
            <Link
              href="https://t.me/PostgresProEdu"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "35px",
                height: "35px",
                mr: 4,
                color: basicColors.secondary.main,
                backgroundColor: "#5E5E5E",
                borderRadius: "50%"
              }}
            >
              <TelegramIcon />
            </Link>
            <Link
              href="mailto:info@pgstart.ru"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "35px",
                height: "35px",
                color: basicColors.secondary.main,
                backgroundColor: "#5E5E5E",
                borderRadius: "50%"
              }}
            >
              <EmailIcon />
            </Link>
          </Stack>
        </Stack>
        <Stack
          direction={isMiddleScreen ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isMiddleScreen ? "flex-start" : "center"}
        >
          <Typography
            variant="body2"
            color={basicColors.secondary.lightDark}
            sx={{
              mb: isMiddleScreen ? 5 : 0
            }}
          >
            © Postgres Professional 2015 — {getCurrentYear()}
          </Typography>
          <Link
            href="https://postgrespro.ru/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: isMiddleScreen ? 5 : 0,
              color: basicColors.secondary.lightDark,
              textDecoration: "none"
            }}
          >
            <LanguageIcon />
            <Typography
              variant="body2"
              sx={{ ml: 4 }}
            >
              postgrespro.ru
            </Typography>
          </Link>
          <Link
            href="tel:+74951500691"
            sx={{
              color: basicColors.secondary.lightDark,
              textDecoration: "none"
            }}
          >
            <Typography variant="body1">+7 495 150 06 91</Typography>
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};
