import { MenuItem, Select, FormHelperText, FormControl, InputLabel } from '@mui/material';

export const FieldSelect = ({ selectList, value, error, helperText, label, onChange }) => {

  return (
    <>
      <FormControl error={error}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          value={value}
          onChange={onChange}
          MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: 3,
                  border: " thin solid rgb(123, 123, 124)",
                },
              }
            }}
        >
          {selectList.map((field) => (
            <MenuItem key={field.internshipCode} value={field.internshipCode}>
              {field.internshipName}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};
