import React from 'react';
import { Box, Button, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'theme';
import { basicColors } from 'theme/basic-colors';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';


export const HeroSection = ({ onClick, admission }) => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      component="section"
      sx={{
        overflow: "hidden"
      }}
    >
      <Stack
        direction="column"
        sx={{
          position: "relative",
          maxWidth: "1072px",
          margin: "0 auto",
          px: isMiddleScreen ? 6 : 3.5,
          pt: isMiddleScreen ? 14 : 16,
          zIndex: 1
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 40,
            right: -70,
            width: 295,
            height: 295,
            backgroundImage: "url('/img/hero/bg-img1.png')",
            backgroundSize: "cover",
            zIndex: -1,
            [theme.breakpoints.down("md")]: {
              bottom: 145,
              right: -130
            }
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 90,
            right: 14,
            width: 269,
            height: 242,
            backgroundImage: "url('/img/hero/bg-img2.png')",
            backgroundSize: "cover",
            zIndex: -2,
            [theme.breakpoints.down("md")]: {
              bottom: 145,
              left: -100
            }
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 300,
            right: 110,
            width: 226,
            height: 166,
            backgroundImage: "url('/img/hero/bg-img3.png')",
            backgroundSize: "cover",
            zIndex: -1,
            [theme.breakpoints.down("md")]: {
              top: 0,
              right: -15,
              width: 122,
              height: 90
            }
          }}
        />

        <Stack
          direction="column"
          sx={{
            marginBottom: isMiddleScreen ? 15 : 22.5
          }}
        >
          <Typography
            variant="h1"
            sx={{
              marginBottom: 4,
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            PGStart.
            <Box
              component="span"
              sx={{
                fontWeight: 600,
                color: basicColors.primary.main,
                "@media(max-width:991px)": {
                  fontSize: "2.5rem",
                  pr: 30
                }
              }}
            >
              стажировки
            </Box>
          </Typography>
          <Stack
            direction={isMiddleScreen ? "column" : "row"}
            justifyContent="space-between"
            sx={{ marginBottom: 17.5 }}
          >
            <Stack
              direction="column"
              alignItems="flex-start"
            >
              {admission && (
                <Typography
                  variant="body1"
                  sx={{
                    width: isMiddleScreen ? "100%" : 444,
                    mb: isMiddleScreen ? 5 : 0
                  }}
                >
                  Оплачиваемая стажировка в команде самой популярной российской СУБД. Гибкий график и
                  поддержка наставников
                </Typography>
              )}
              {!admission && (
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography variant="body2">
                  Сейчас прием заявок на стажировку закрыт, спасибо за интерес к проекту!
                </Typography>
                  <Typography variant="body2">
                  Присоединяйся к телеграм-каналу
                    {" "}
                  <Link
                    href="https://t.me/PostgresProEdu"
                    sx={{ color: basicColors.black }}
                  >
                    Postgres Pro Edu
                  </Link>
                  , там мы опубликуем информацию о начале набора на стажировки.
                </Typography>
                </Box>
                )
              }
            </Stack>
            { admission && (
              <Stack
                direction="column"
                sx={{
                  flexShrink: 0,
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "auto"
                  }}
                  endIcon={<ArrowRightIcon />}
                  onClick={onClick}
                >
                  Отправить заявку
                </Button>
              </Stack>
            )
          }
          </Stack>
          <Stack
            id="img-block"
            direction="row"
            sx={{
              display: "flex",
              justifyContent: isMiddleScreen ? "center" : "flex-start",
              flexWrap: "wrap",
              width: isMiddleScreen ? "100%" : 688
            }}
          >
            <Box
              sx={{
                display: isMiddleScreen ? "none" : "block",
                borderRadius: 8,
                backgroundImage: "url('/img/hero/img1.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: 156,
                height: 176,
                padding: 5,
                marginBottom: 5,
                marginRight: isMiddleScreen ? 0 : 5
              }}
            />
            <Box
              sx={{
                width: isMiddleScreen ? "100%" : 245,
                height: isMiddleScreen ? "auto" : 176,
                borderRadius: 8,
                padding: 5,
                backgroundColor: basicColors.primary.main,
                marginBottom: 5,
                marginRight: isMiddleScreen ? 0 : 5
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 400,
                  color: basicColors.white
                }}
              >
                Официальное трудоустройство, гибридный формат — возможность работать удаленно или в
                офисе (Москва, Барнаул, Новосибирск)
              </Typography>
            </Box>
            <Box
              sx={{
                display: isMiddleScreen ? "none" : "block",
                borderRadius: 8,
                backgroundImage: "url('/img/hero/img2.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: 247,
                height: 176,
                marginBottom: 5
              }}
            />
            <Box
              sx={{
                borderRadius: 8,
                backgroundImage: "url('/img/hero/img3.jpg')",
                backgroundRepeat: "no-repeat",
                width: isMiddleScreen ? "100%" : 525,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: 194,
                marginRight: isMiddleScreen ? 0 : 5
              }}
            />
            <Box
              sx={{
                display: isMiddleScreen ? "none" : "block",
                borderRadius: 8,
                backgroundImage: "url('/img/hero/img4.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: 143,
                height: 194
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
