import React from 'react';
import { SvgIcon } from '@mui/material';

export const IconPlus = (rest) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...rest}>
      <path d="M8.5 8.5V0.5H11.5V8.5H19.5V11.5H11.5V19.5H8.5V11.5H0.5V8.5H8.5Z" />
    </SvgIcon>
  );
};
