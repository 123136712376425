export const MuiMenuItem = {
  styleOverrides: {
    root:({theme})=>({
      padding: "12px 16px 12px 16px",
      color: theme.palette.common.black,
      fontFamily: "Manrope",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "22px",
    })
  }
}