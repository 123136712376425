import { basicColors } from '../basic-colors.js';

const PRIMARY_FONT = ['Roboto', 'sans-serif'].join(',');
const SECONDARY_FONT = ['Syne', 'sans-serif'].join(',');

export const themeOptions = {
  spacing: 4,
  palette: {
    background: {
      default: basicColors.white,
      paper: basicColors.white
    },
    primary: {
      main: basicColors.primary.main,
      contrastText: basicColors.white
    },
    common: {
      pink: basicColors.pink,
      black: basicColors.black,
      white: basicColors.white
    },

    text: {
      primary: basicColors.black
    }
  },
  typography: {
    fontFamily: PRIMARY_FONT,
    h1: {
      fontFamily: SECONDARY_FONT,
      fontSize: '4.375rem',
      fontWeight: 400,
      textAlign: 'left',
      lineHeight: 'normal',
      margin: 0,
      '@media(max-width: 991px)': {
        fontSize: '2.5rem'
      }
    },
    h2: {
      fontFamily: SECONDARY_FONT,
      fontSize: '4.375rem',
      fontWeight: 400,
      textAlign: 'left',
      lineHeight: 'normal',
      margin: 0,
      '@media(max-width: 991px)': {
        fontSize: '2.5rem'
      }
    },
    h3: {
      fontFamily: PRIMARY_FONT,
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: 'normal',
      fontSize: '2.125rem',
      '@media(max-width: 991px)': {
        fontSize: '1.5rem'
      }
    },
    h4: {
      fontFamily: PRIMARY_FONT,
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: 'normal',
      fontSize: '1.5rem'
    },

    body1: {
      fontFamily: PRIMARY_FONT,
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      '@media(max-width: 991px)': {
        fontSize: '1rem'
      }
    },

    body2: {
      fontFamily: PRIMARY_FONT,
      fontSize: '1rem',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: 'normal'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
  }
};
