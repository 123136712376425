import { alpha } from '@mui/material';

export const MuiChip = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      fontSize: '1.25rem',
      height: 40,
      fontWeight: 700,
      borderRadius: 20,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: alpha(theme.palette.common.pink, 0.4)
      },
      '&.MuiChip-clickable:hover': {
        backgroundColor: alpha(theme.palette.common.pink, 0.7),
        borderColor: alpha(theme.palette.common.pink, 0.7)
      },

      '& .MuiChip-label': {
        paddingLeft: 16,
        paddingRight: 16
      }
    }),
    contained: ({ theme }) => ({
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.pink,
      border: '2px solid',
      borderColor: theme.palette.common.pink,

      '&:focus': {
        backgroundColor: theme.palette.common.pink
      }
    }),
    outlined: ({ theme }) => ({
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: '2px solid',
      borderColor: theme.palette.common.pink,
      '&:hover': {
        color: theme.palette.common.black,
        backgroundColor: alpha(theme.palette.common.pink, 0.4)
      }
    })
  }
};
