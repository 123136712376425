export const checkPersonName = (v) => {
  const re = /^([А-Яа-яA-Za-zёЁ\-\s])+$/;
  return re.test(String(v));
}

export const checkPhone = (v) => {
  const re =
    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-. /]?)?((?:\(?\d{1,}\)?[-. /]?){0,})(?:[-. /]?(?:#|ext\.?|extension|x)[-./]?(\d+))?$/;
  return re.test(String(v));
}

export const checkTelegram = (v) => {
  const re = RegExp("^(@|https://t.me/)?(?=.{5,32}$)(?!.*__)(?!^(telegram|admin|support))[a-z][a-z0-9_]*[a-z0-9]$", "i");
  return re.test(String(v));
}

export const checkEmail = (v) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(v));
};
